import { CssVarsThemeOptions } from '@mui/material';
import { deepmerge } from '@mui/utils';
import AppliedAutomationLogo from '@/app/[tenantAlias]/appliedautomation/_assets/applied-automation-logo.svg';
import AppliedAutomationWhiteLogo from '@/app/[tenantAlias]/appliedautomation/_assets/applied-automation-white-logo.svg';
import {
  createMetadata,
  createOrganizationJSONLD,
} from '@/global/config/ConfigHelper';
import { SPSConfig } from '@/global/types/SPSConfig';
import { globalComponentsMap } from '@/globalComponents/global-components-map';
import { TenantAlias } from '@/shared/tenants-alias-map';

const TENANT_ALIAS: TenantAlias = 'appliedautomation';

/**
 * Copy and paste the generated theme config from the Figma design using `Material UI Sync plugin`.
 * @see https://mui.com/material-ui/design-resources/material-ui-sync/
 */
const themeFromFigma: CssVarsThemeOptions = {
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: '#CB090D',
          dark: '#7A0104',
          light: '#F99494',
          '50p': '#D78887',
          '30p': '#E7B8B8',
          '12p': '#F4E2E2',
          '8p': '#F8ECEC',
          '4p': '#FCF5F6',
          contrastText: '#FFF',
        },
        secondary: {
          main: '#1B1B1B',
          dark: '#1B1B1B',
          light: '#565656',
          '50p': '#8D8D8D',
          '30p': '#BBBBBB',
          '12p': '#E3E3E3',
          '8p': '#EDEDED',
          '4p': '#F6F6F6',
          contrastText: '#FFF',
        },
      },
    },
  },
};

/**
 * Any local overrides can be applied here:
 */
const localOverrides: CssVarsThemeOptions = {};

const HUMAN_READABLE_TENANT_NAME = 'Applied Automation';
const SITE_NAME = `${HUMAN_READABLE_TENANT_NAME} Site`;
const HOST = `https://${TENANT_ALIAS}.salespilotsites.com`;
const SITE_DESCRIPTION = `An Applied Automation Sales Pilot instance`;
const EMAIL = 'sales@appliedautomation.com';
const PHONE = '315-453-5670';
const STREET = '1240 Wolf Street';
const CITY = 'Syracuse';
const STATE = 'NY';
const ZIP = '13208';
const COUNTRY = 'USA';

export const spsConfig: SPSConfig = {
  tenantAlias: TENANT_ALIAS,
  humanReadableTenantName: HUMAN_READABLE_TENANT_NAME,
  siteName: SITE_NAME,
  host: HOST,
  siteDescription: SITE_DESCRIPTION,
  email: EMAIL,
  phone: PHONE,
  street: STREET,
  city: CITY,
  state: STATE,
  zip: ZIP,
  country: COUNTRY,
  tenantTheme: deepmerge(themeFromFigma, localOverrides),
  metadata: createMetadata(
    TENANT_ALIAS,
    SITE_NAME,
    HOST,
    SITE_DESCRIPTION,
    HUMAN_READABLE_TENANT_NAME,
  ),
  organizationJSONLD: createOrganizationJSONLD(
    TENANT_ALIAS,
    SITE_NAME,
    HOST,
    SITE_DESCRIPTION,
    EMAIL,
    PHONE,
    {
      streetAddress: STREET,
      addressLocality: CITY,
      addressRegion: STATE,
      postalCode: ZIP,
      addressCountry: COUNTRY,
    },
  ),
  componentDirectory: globalComponentsMap,
  headerLogo: AppliedAutomationLogo,
  headerLogoWidth: 138,
  headerLogoHeight: 50,
  headerLinks: [
    {
      href: '/',
      label: 'Home',
    },
    {
      href: '/services',
      label: 'Services',
    },
    {
      href: '/about',
      label: 'About us',
    },
    {
      href: '/contact',
      label: 'Contact',
    },
  ],
  footerLogo: AppliedAutomationWhiteLogo,
  footerLogoWidth: 433,
  footerLogoHeight: 158,
  footerText: '',
  footerLinks: [
    { label: 'Home', href: '/' },
    { label: 'About Us', href: '/about' },
    { label: 'Contact Us', href: '/contact' },
    { label: 'Value Added Services', href: '/services' },
  ],
};
